<template>
  <v-container class="pt-120">
    <h1>Terms of Service</h1>
    <h3>
      PLEASE READ THE FOLLOWING TERMS AND CONDITIONS OF USE CAREFULLY BEFORE
      USING THIS WEBSITE.
    </h3>
    <p>
      These terms and conditions ("Terms", "Agreement") are an agreement between
      Rave Now LLC ("Rave Now LLC", “RaveVIP.com”, "us", "we" or "our") and you
      ("Artist/Talent", "you" or "your"). This Agreement sets forth the general
      terms and conditions of your use of the ravevip.com website and any of its
      products or services (collectively, "Website" or "Services").
    </p>

    <h3>Accounts and membership</h3>
    <p>
      If you create an account on the Website, you are responsible for
      maintaining the security of your account and you are fully responsible for
      all activities that occur under the account and any other actions taken in
      connection with it. Providing false information of any kind may result in
      the termination of your account. You must immediately notify us of any
      unauthorized uses of your account or any other breaches of security. We
      will not be liable for any acts or omissions by you, including any damages
      of any kind incurred as a result of such acts or omissions.
    </p>

    <h3>Payments</h3>
    <p>
      As a provider of services through RaveVIP.com Interactions, you have
      entered into an Artist/Talent Agreement prior to setting up your online
      account. All uses of your account are subject to that Agreement. As a
      service to you, you should provide us with a copy of your valid
      government-issued photo identification. We reserve the right to change
      products and product pricing at any time. By our Artist/Talent Agreement,
      we also reserve the right to refuse or terminate any relationship with
      you. We may, in our sole discretion, limit or cancel transactions. In the
      event that we make a change to or cancel an order, we will attempt to
      notify you through the contact information provided to us in the
      Artist/Talent Agreement.
    </p>
    <p>
      You must register with stripe.com and provide the required bank account
      information in order to receive payment from Rave Now, LLC. Payment will
      be made within two (2) weeks of receipt by us of payment from the User.
      Except in the capacity of a vendor, Stripe.com is not operated by or
      associated with Rave Now, LLC and your use of stripe.com is subject to
      their terms. We reserve the right to change payment providers at any time
      and to require you to sign up with that new payment provider. We are not
      responsible for any delays or failures to receive payment caused by a
      third party payment provider or by your failure to timely or properly set
      up an account with the payment provider or to otherwise provide requested
      information for payment.
    </p>

    <h3>Accuracy of information</h3>
    <p>
      Occasionally there may be information on the Website that contains
      typographical errors, inaccuracies or omissions that may relate to product
      descriptions, pricing, availability, promotions and offers. We reserve the
      right to correct any errors, inaccuracies or omissions, and to change or
      update information or cancel orders if any information on the Website or
      on any related Service is inaccurate at any time without prior notice. We
      undertake no obligation to update, amend or clarify information on the
      Website, including, without limitation, pricing information, except as
      required by law. No specified update or refresh date applied on the
      Website should be taken to indicate that all information on the Website or
      on any related Service has been modified or updated.
    </p>

    <h3>Prohibited uses</h3>
    <p>
      In addition to other terms as set forth in this and in the Artist/Talent
      Agreement, you are prohibited from using the Website or its Content: (a)
      for any unlawful purpose; (b) to solicit others to perform or participate
      in any unlawful acts; (c) to violate any international, federal,
      provincial or state regulations, rules, laws, or local ordinances; (d) to
      infringe upon or violate our intellectual property rights or the
      intellectual property rights of others; (e) to harass, abuse, insult,
      harm, defame, slander, disparage, intimidate, or discriminate based on
      gender, sexual orientation, religion, ethnicity, race, age, national
      origin, or disability; (f) to submit false or misleading information; (g)
      to upload or transmit viruses or any other type of malicious code that
      will or may be used in any way that will affect the functionality or
      operation of the Service or of any related website, other websites, or the
      Internet; (h) to collect or track the personal information of others; (i)
      to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any
      obscene or immoral purpose; or (k) to interfere with or circumvent the
      security features of the Service or any related website, other websites,
      or the Internet. We reserve the right to terminate your use of the Service
      or any related website for violating any of the prohibited uses.
    </p>

    <h3>Intellectual property rights</h3>
    <p>
      This Agreement does not transfer to you any intellectual property owned by
      Rave Now LLC or third-parties, and all rights, titles, and interests in
      and to such property will remain (as between the parties) solely with Rave
      Now LLC. All trademarks, service marks, graphics and logos used in
      connection with our Website or Services, are trademarks or registered
      trademarks of Rave Now LLC or Rave Now LLC licensors. Other trademarks,
      service marks, graphics and logos used in connection with our Website or
      Services may be the trademarks of other third-parties. Your use of our
      Website and Services grants you no right or license to reproduce or
      otherwise use any Rave Now LLC or third-party trademarks.
    </p>

    <h3>Disclaimer of warranty</h3>
    <p>
      You agree that your use of our Website or Services is solely at your own
      risk. You agree that such Service is provided on an "as is" and "as
      available" basis. We expressly disclaim all warranties of any kind,
      whether expressed or implied, including but not limited to the implied
      warranties of merchantability, fitness for a particular purpose and
      non-infringement. We make no warranty that the Services will meet your
      requirements, or that the Service will be uninterrupted, timely, secure,
      or error-free; nor do we make any warranty as to the results that may be
      obtained from the use of the Service or as to the accuracy or reliability
      of any information obtained through the Service or that defects in the
      Service will be corrected. No advice or information, whether oral or
      written, obtained by you from us or through the Service shall create any
      warranty not expressly made herein.
    </p>

    <h3>Limitation of liability</h3>
    <p>
      To the fullest extent permitted by applicable law, in no event will Rave
      Now LLC, its affiliates, officers, directors, employees, agents, suppliers
      or licensors be liable to any person for (a): any indirect, incidental,
      special, punitive, cover or consequential damages (including, without
      limitation, damages for lost profits, revenue, sales, goodwill, use or
      content, impact on business, business interruption, loss of anticipated
      savings, loss of business opportunity) however caused, under any theory of
      liability, including, without limitation, contract, tort, warranty, breach
      of statutory duty, negligence or otherwise, even if Rave Now LLC has been
      advised as to the possibility of such damages or could have foreseen such
      damages. To the maximum extent permitted by applicable law, the aggregate
      liability of Rave Now LLC and its affiliates, officers, employees, agents,
      suppliers and licensors, relating to the services will be limited to an
      amount greater of one dollar or any amounts actually paid in cash by you
      to Rave Now LLC for the prior one month period prior to the first event or
      occurrence giving rise to such liability. The limitations and exclusions
      also apply if this remedy does not fully compensate you for any losses or
      fails of its essential purpose.
    </p>

    <h3>Indemnification</h3>
    <p>
      You agree to indemnify and hold Rave Now LLC and its affiliates,
      directors, officers, employees, and agents harmless from and against any
      liabilities, losses, damages or costs, including reasonable attorneys'
      fees, incurred in connection with or arising from any third-party
      allegations, claims, actions, disputes, or demands asserted against any of
      them as a result of or relating to your Interactions, your use of the
      Website or Services or any willful misconduct on your part.
    </p>

    <h3>Severability</h3>
    <p>
      All rights and restrictions contained in this Agreement may be exercised
      and shall be applicable and binding only to the extent that they do not
      violate any applicable laws and are intended to be limited to the extent
      necessary so that they will not render this Agreement illegal, invalid or
      unenforceable. If any provision or portion of any provision of this
      Agreement shall be held to be illegal, invalid or unenforceable by a court
      of competent jurisdiction, it is the intention of the parties that the
      remaining provisions or portions thereof shall constitute their agreement
      with respect to the subject matter hereof, and all such remaining
      provisions or portions thereof shall remain in full force and effect.
    </p>

    <h3>Dispute resolution</h3>
    <p>
      The formation, interpretation, and performance of this Agreement and any
      disputes arising out of it shall be governed by the substantive and
      procedural laws of Wyoming, United States without regard to its rules on
      conflicts or choice of law and, to the extent applicable, the laws of The
      United States. The exclusive jurisdiction and venue for actions related to
      the subject matter hereof shall be the state and federal courts located in
      Wyoming, United States, and you hereby submit to the personal jurisdiction
      of such courts. You hereby waive any right to a jury trial in any
      proceeding arising out of or related to this Agreement. The United Nations
      Convention on Contracts for the International Sale of Goods does not apply
      to this Agreement.
    </p>

    <h3>Assignment</h3>
    <p>
      You may not assign, resell, sub-license or otherwise transfer or delegate
      any of your rights or obligations hereunder, in whole or in part, without
      our prior written consent, which consent shall be at our own sole
      discretion and without obligation; any such assignment or transfer shall
      be null and void. We are free to assign any of its rights or obligations
      hereunder, in whole or in part, to any third-party as part of the sale of
      all or substantially all of its assets or stock or as part of a merger.
    </p>

    <h3>Changes and amendments</h3>
    <p>
      We reserve the right to modify this Agreement or its policies relating to
      the Website or Services at any time, effective upon posting of an updated
      version of this Agreement on the Website. When we do, we will revise the
      updated date at the bottom of this page. Continued use of the Website
      after any such changes shall constitute your consent to such changes.
    </p>

    <h3>Acceptance of these terms</h3>
    <p>
      You acknowledge that you have read this Agreement and agree to all its
      terms and conditions. By using the Website or its Services you agree to be
      bound by this Agreement. If you do not agree to abide by the terms of this
      Agreement, you are not authorized to use or access the Website and its
      Services.
    </p>

    <h3>Contacting us</h3>
    <p>
      If you have any questions about this Agreement, please contact us.
    </p>
    <p>
      This document was last updated on February 11, 2019
    </p>
  </v-container>
</template>
<script>
export default {
  name: 'TalentTermsOfService',
  beforeMount() {
    this.$store.commit('signupModal', false)
  },
}
</script>
